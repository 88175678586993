export function rn(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function rs(len) {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array(len).fill(0).map(() => chars[rn(0, chars.length - 1)]).join("");
}

export function goHome(ms=0) {
    setTimeout(() => window.location = "/", ms);
}

export function setUrl(path) {
    window.history.pushState({}, "", path);
}

export function getParam(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
}

export function getManyParams(...names) {
    const params = new URLSearchParams(window.location.search);
    // return as {name:value, name2: value2}
    return names.reduce((acc, name) => {
        acc[name] = params.get(name);
        return acc;
    }, {});
    // return names.map(name => ({ name: params.get(name) }))
    
}


export function isDarkTheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function calcDiscountPct(price, price_before) {
    return Math.round((1 - price / price_before) * 100);
}

// if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
//     document.documentElement.classList.add('dark');
// } else {
//     document.documentElement.classList.remove('dark');
// }
// window.matchMedia('(prefers-color-scheme: dark)').matches ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark');

// if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
//     document.documentElement.classList.add('dark')
// } else {
//     document.documentElement.classList.remove('dark')
// }

// export async function switchTheme() {
//     if (localStorage.theme === 'dark') {
//         localStorage.theme = 'light'
//         document.documentElement.classList.remove('dark')
//     } else {
//         localStorage.theme = 'dark'
//         document.documentElement.classList.add('dark')
//     }
// }

// export function getTheme() {
//     return localStorage.theme || 'light';
// }
