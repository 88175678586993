import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiGetMediaData } from "../utils/Vantage";

export default function MediaPage() {
  const [data, setData] = useState(null);
  const [totalEarned, setTotalEarned] = useState(0);
  const navigate = useNavigate();
  const { code } = useParams();

  useEffect(() => {
    apiGetMediaData(code).then(({ ok, data }) => {
      if (!ok) return navigate("/404");
      setData(data);
      const d = new Date();
      const dStart = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    
      setTotalEarned(data.uses.reduce((sum, entry) => {
        const date = new Date(entry.time * 1000);
        if (date >= dStart) return sum + entry.earned;
        return sum;
      }, 0));
    
    }, 2000);
  }, [code, navigate]);

  return (
    <main className="min-h-full dark:bg-gray-900 p-8">
      <h1 className="text-4xl font-semibold text-gray-800 dark:text-gray-100 mb-6 text-center">
        Media Dashboard
      </h1>
      <hr className="w-32 m-auto mb-8 bg-gray-900" />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Left Box */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Your Revenue Today
          </h2>
          {data ? (
            <p className="text-lg text-green-500">${totalEarned}</p>
          ) : (
            <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>
          )}
        </div>

        {/* Middle Box */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Total Revenue
          </h2>
          {data ? (
            // get sum of all revenue, the uses list has objects, which have the `earned` property, which is the revenue
            <p className="text-lg text-blue-500">${data.uses.reduce((sum, entry) => sum + entry.earned, 0)}</p>
          ) : (<div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>)}
        </div>

        {/* Right Box */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Discount Amount
          </h2>
          {data ? (
            <p className="text-lg text-purple-500">{data.percent}%</p>
            ) : (<div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>)}
        </div>
      </div>

      <div className="flex flex-row w-full mt-5">
        {/* Left Box */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-1/2 mr-2">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Coupon Code
          </h2>
          {data ? (
            <p className="text-lg text-yellow-500">{data._id}</p>
          ) : (
            <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>
          )}
        </div>
        {/* Right Box */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-1/2 ml-2">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Your Cut
          </h2>
          {data ? (
            <p className="text-lg text-lime-500">{data.cut}%</p>
          ) : (<div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>)}
        </div>
      </div>


      {/* Show coupon info */}
      {/* <div className="mt-5">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-200">
            Coupon Code
          </h2>
          {data ? (
            <p className="text-lg text-blue-500">10OFF</p>
          ) : (
            <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg animate-pulse"></div>
          )}
        </div>
      </div> */}


      {/* Order Table */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">Order History</h2>
        <table className="min-w-full bg-white dark:bg-gray-800 border divide-y text-gray-700 divide-gray-300 dark:divide-gray-700 dark:text-gray-300 uppercase tracking-wider">
          <thead className="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium">
                Username
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium">
                User ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium">
                Purchased At
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium">
                Earned
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300 text-gray-500 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800">
            {data ? (
              data.uses.reverse().map((order, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200 ">
                    {order.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {order.user_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {new Date(order.time * 1000).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    ${order.earned}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200 ">
                  <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm animate-pulse">
                  <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm animate-pulse">
                  <div className="h-5 w-[150px] dark:bg-gray-600 rounded-lg"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm animate-pulse">
                  <div className="h-5 w-[125px] dark:bg-gray-600 rounded-lg"></div>
                </td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm animate-pulse">
                  <div className="h-5 w-[125px] dark:bg-gray-600 rounded-lg"></div>
                </td> */}
              </tr>
            )}
            {/* Add more rows here */}
          </tbody>
        </table>
      </div>
    </main>
  );
}
