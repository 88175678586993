import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getParam } from "../../utils/General";
import { apiVerifyEmail } from "../../utils/Vantage";
import { showError, showSuccess } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";

export default function EmailVerifyPage() {
  const navigate = useNavigate();
  const captcha = useRef(null);

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white dark:bg-gray-900">
      <div className="max-w-xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800 dark:text-zinc-200">Finish Verification</h2>
        <p className="mb-2 text-lg text-zinc-500 dark:text-zinc-500">To protect against fradulent activity, please complete this captcha. If you have a problem, contact <a className="font-medium text-indigo-500" href="mailto:support@vantage.rip">support@vantage.rip</a>.</p>
        <button className="rounded-md bg-indigo-600 px-5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() =>  captcha.current.execute()}>
          Finish Verification
        </button>
        <div className="text-center flex align-middle justify-center">
           <ReCAPTCHA
            sitekey="6LebnT0mAAAAAGtF8aAa4A9HsGk1A8q8q4AuDhK7"
            ref={captcha}
            size="invisible"
            theme="dark"
            onChange={async(captcha) => {
              const token = getParam("token");
              if (!captcha) return showError("Please complete the captcha");
              if (!token) return showError("No verification token provided");
              const { ok, msg } = await apiVerifyEmail(token, captcha);
              if (!ok) {
                window.grecaptcha.reset();
                return showError(msg);
              }
              showSuccess(msg);
              setTimeout(() => navigate("/"), 1000);
            }}
          />
        </div>
      </div>
    </div>
  )
}
