/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, BoltIcon, CloudIcon, PhoneIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import { apiGetProducts, apiGetUser, apiLogout, apiResendVerificationEmail } from "../utils/Vantage";
import { Features, Feature } from "../components/Features";
import ProductCardList from "../components/Product/ProductCardList";
import { showSuccess } from "../utils/Toast";
import Link from "../components/Link";
import { isDarkTheme } from "../utils/General";

const navigation = [
  { name: "Products", href: "#products" },
  { name: "Features", href: "#features" },
  { name: "Privacy", href: "/privacy" },
  { name: "Contact", href: "mailto:support@vantage.rip?subject=Support Ticket&body=[Place your request here]\n\n\n" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function IndexPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [products, setProducts] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    apiGetUser().then((resp) => {
      setUser(resp.data);
    });
    apiGetProducts().then((resp) => {
      setProducts(resp.data);
    });
  }, []);

  return (
    <div className="bg-white dark:bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <span className="-m-1.5 p-1.5">
              <span className="sr-only">Vantage</span>
              <img
                className="h-8 w-auto"
                src="/logo512.png"
                alt=""
              />
            </span>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 dark:bg-gray-200" id="open-button">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="/img/icon/user.png"
                    alt="User profile image"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-300")}
                        {...{ to: user ? "#" : "/auth/login" }}>
                        {user ? "Welcome, " + user.username : "Login"}
                      </Link>
                    )}
                  </Menu.Item>
                  {user &&
                    <>
                      {(user.role === 0) && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => apiResendVerificationEmail().then(({ msg }) => {
                                showSuccess(msg);
                              })}
                              className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-red-500 hover:cursor-pointer dark:bg-gray-800 dark:text-gray-300")}
                            >
                              Resend verification email
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/connections"
                            className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-300")}
                          >
                            Connections
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              navigator.clipboard.writeText(user._id);
                              showSuccess("Copied UID to clipboard");
                            }}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer dark:bg-gray-800 dark:text-gray-300')}
                          >
                            Copy UID
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => apiLogout().then(() => {
                              setUser(null);
                              showSuccess("You have been logged out");
                            })}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-red-500 hover:cursor-pointer dark:bg-gray-800')}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </>
                  }
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <span className="-m-1.5 p-1.5">
                <span className="sr-only">Vantage</span>
                <img
                  className="h-8 w-auto"
                  src="/logo512.png"
                  alt=""
                />
              </span>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <button
                    onClick={(e) => {
                      { !user && e.ctrlKey ? window.open("/login", "_blank") : navigate("/auth/login"); }
                    }}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {user ? user.username.toUpperCase() : "Log-in"}
                  </button>
                  {user && (
                    <>
                      <button
                        onClick={() => navigator.clipboard.writeText(user._id)}
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Copy UID
                      </button>
                      <button onClick={() => apiLogout().then(() => {
                        setUser(null);
                        showSuccess("You have been logged out");
                      }
                      )} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-red-900 hover:bg-gray-50">
                        Sign out
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-white">
              Vantage Marketplace
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200">
              Where you shop and sell. Buy anything related to gaming. Modifications for games, clients, and whatnot.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={user ? "#products" : "/auth/register"}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {user ? "See products" : "Get started"}
              </Link>
              {!user && (
                <a href="#products" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                  See products <span aria-hidden="true">&raquo;</span>
                </a>
              )}
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>

      <Features features={[
        new Feature("Instant Delivery", "Instant delivery on all products. No waiting, no hassle. Just buy and use.", BoltIcon),
        new Feature("24/7 Support", "We have a dedicated support team that is available 24/7 to help you with any issues you may have.", XMarkIcon),
        new Feature("99.99% Uptime", "We're always online. We have a 99.99% uptime guarantee, letting you access what you want, whenever you want.", CloudIcon),
        new Feature("Satisfaction Guaranteed", "We guarantee that you will be satisfied with your purchase. We will provide everything required to serve you..", PhoneIcon)
      ]} id={"features"} />


      <div id={"products"}></div>

      {products ?
        Object.entries(products).map(([category, products]) => {
          return <ProductCardList title={`${category} Products`} products={products} id={`${category}-products`} key={`${category}-products`} />;
        })
        : <div className="flex justify-center items-center h-96">
          <svg className="animate-spin h-5 w-5 mr-3 scale-150" viewBox="0 0 24 24">
            <EllipsisHorizontalIcon color={isDarkTheme() ? "lightblue" : "black"} />
          </svg>
        </div>
      }
    </div>
  )
}
