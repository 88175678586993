import { Link as ReactLink } from "react-router-dom";

export default function Link({ ...props }) {
  if (props.to.startsWith("#")) {
    return (
      // eslint-disable-next-line
      <a title="Outside Link" aria-label="Outside Link" href={props.to} {...props} />
    )
  }
  return (
    <ReactLink {...props} />
  )
}
