/* eslint-disable */
import ProductDevModal from "../../Modal/ProductDevModal";
import { Fragment, useState, useEffect, cloneElement } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { apiGetProduct, apiChangeHwid } from "../../../utils/Vantage";
import { showError, showSuccess } from "../../../utils/Toast";

export default function ProductManageModal({ btn, productId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [hwid, setHwid] = useState(null);

  function fetchProductData() {
    apiGetProduct(productId).then(({ ok, data }) => {
      if (ok) setProductData(data);
    });
  }

  useEffect(() => {
    if (!isOpen) return;
    fetchProductData();
  }, [isOpen]);

  return (
    <>
      { btn && cloneElement(btn, { onClick: () => setIsOpen(true) })}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900 dark:text-gray-100"
                  >
                    Manage Metadata
                  </Dialog.Title>
                  <form onSubmit={async(e) => {
                    e.preventDefault();
                    if (hwid === productData.hwid) return showError("HWID is the same as before!");
                    const { ok, msg } = await apiChangeHwid(productId, hwid);
                    if (!ok) return showError(msg);
                    showSuccess(msg);
                    fetchProductData();
                    setIsOpen(false);
                  }}>
                    <div className="mt-2">
                      <input type="text" placeholder="Hardware ID" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" autoComplete="off" defaultValue={productData ? productData.hwid : ""} id={`${productId}-hwid`} onChange={e => setHwid(e.target.value)} />
                    </div>

                    <div className="mt-4 flex justify-between">
                      <div>
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                          Update
                        </button>

                        <button
                          type="button"
                          class="ml-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                          onClick={() => setIsOpen(false)}>
                          Cancel
                        </button>

                      </div>
                      {productData && productData.owner && (
                        <ProductDevModal productId={productId} btn={(
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-gray hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          >
                            Dev Panel
                          </button>
                        )} />)}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
