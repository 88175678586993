/* eslint-disable */
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import EmailVerifyPage from "./pages/Auth/EmailVerifyPage";
import NotFoundPage from "./pages/Errors/NotFoundPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import DiscordCallbackPage from "./pages/Callback/Discord";
import PaymentCallbackPage from "./pages/Callback/Payment";
import PaymentCancelPage from "./pages/Callback/PaymentCancel";
import ConnectionsPage from "./pages/ConnectionsPage";
import ResetPage from "./pages/Auth/ResetPage";
import MediaPage from "./pages/MediaPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<IndexPage />} />
        <Route path="/auth">
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="reset" element={<ResetPage />} />
          <Route path="verify" element={<EmailVerifyPage />} />
        </Route>
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/callback">
          <Route path="discord" element={<DiscordCallbackPage />} />
          <Route path="payment" element={<PaymentCallbackPage />} />
          <Route path="pcancel" element={<PaymentCancelPage />} />
        </Route>
        {/* path /media/:code */}
        <Route path="/media/:code" element={<MediaPage />} />
        <Route path="/connections" element={<ConnectionsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
