import { showError, showSuccess } from "./Toast";

const IS_DEV = process.env.NODE_ENV === "development";
const baseUrl = IS_DEV ? "http://localhost:3001" : "https://api.vantage.rip";

export class Response {
    constructor(ok, msg, data = null) {
        this.ok = ok;
        this.msg = msg;
        this.data = data;
    }
}

// const errorHistory = [];

function getUrl(path) {
    return baseUrl.trim("/") + path;
}

async function makeRequest(path, options) {
    if (options == null || options === undefined) options = {};
    if (options["headers"] == null || options["headers"] === undefined) options["headers"] = {};
    options.headers["Content-Type"] = "application/json";
    options.headers["Application-Type"] = "application/json";
    options["credentials"] = "include";
    if (typeof options.body === "object") options.body = JSON.stringify(options.body);
    let response;
    /* 
    * const date = parseInt((new Date()) / 1000).toString();
    * options.headers = {
    *     ...options.headers,
    *     s: date[0],
    *     x: date[1],
    *     k: date[2],
    *     w: date[3],
    *     y: date[4],
    *     o: date[5],
    *     l: date[6],
    *     u: date[7],
    *     r: date[8],
    *     d: date[9],
    * } */
    try {
        response = await fetch(getUrl(path), options);
    } catch (e) {
        // showError("An error occurred while making the request.");
        return new Response(false, "An error occurred while making the request.");
    }
    if (response.headers.get("Content-Type") !== "application/json") {
        return new Response(response.status === 200, "The response is not JSON", response);
    }
    const json = await response.json();
    // if (response.status === 429) {
    //     showError("You are being ratelimited, please try again later.");
    // }
    // if (response.status === 403 && !errorHistory.includes(json.msg)) {
    //     showError(json.msg);
    //     if (!json.msg.includes("download")) errorHistory.push(json.msg);
    //     return;
    // }
    return new Response(json.ok, json.msg, json.data);
}

export async function showAndNotify(func, ...args) {
    const { ok, msg, data } = await func(...args);
    ok ? showSuccess(msg) : showError(msg);
    return { ok, msg, data };
}

export async function apiGetUser() {
    return await makeRequest("/@me");
}

export async function apiGetConnections() {
    return await makeRequest("/@me/connections");
}

export async function apiDeleteConnection(name) {
    return await makeRequest(`/@me/connections/${name}`, { method: "DELETE" });
}

export async function apiGetProducts() {
    return await makeRequest("/@me/products");
}

export async function apiGetProduct(productId) {
    return await makeRequest(`/@me/products/${productId}`);
}

export async function apiGetPurchaseData(productId) {
    return await makeRequest(`/@me/products/${productId}/purchase-data`);
}

export async function apiChangeHwid(productId, hwid) {
    if (hwid == null) return new Response(false, "No HWID provided.");
    return await makeRequest(`/@me/products/${productId}/hwid`, {
        method: "POST",
        body: { hwid: hwid }
    });
}

export async function apiGetMediaData(coupon) {
    return await makeRequest(`/@me/media?coupon=${coupon}`);
}

// export async function getProductPaymentUrl(productId, method, coupon) {
//     if (productId == null) return new Response(false, "No product ID provided.");
//     if (method == null) return new Response(false, "No payment method provided.");
//     return await makeRequest(`/@me/products/${productId}/purchase?method=${method}&coupon=${coupon}`);
// }

export async function apiLogin(username, password, captcha) {
    return await makeRequest("/auth/login", {
        method: "POST",
        body: { username: username, password: password, captcha: captcha }
    });
}

// export async function apiLogin(username, password, token) {
//     return await makeRequest("/auth/login", {
//         method: "POST",
//         body: { username: username, password: password, captcha: token }
//     });
// }

export async function apiRegister({ username, email, password, captcha }) {
    return await makeRequest("/auth/register", {
        method: "POST",
        body: {
            username: username,
            password: password,
            captcha: captcha,
            email: email
        }
    });
}


// export async function apiChangePassword(oldPassword, newPassword) {
//     return await makeRequest("/@me/password", {
//         method: "POST",
//         body: { oldPassword: oldPassword, newPassword: newPassword }
//     });
// }

export async function apiResendVerificationEmail() {
    return await makeRequest("/auth/resend-verify", { method: "POST" });
}

export async function apiVerifyEmail(token, captcha) {
    return await makeRequest("/auth/verify-email", {
        method: "POST",
        body: { token: token, captcha: captcha }
    });
}

export async function apiSendResetPassword(email, captcha) {
    return await makeRequest("/auth/send-reset-password", {
        method: "POST",
        body: { email: email, captcha: captcha }
    });
}

export async function apiResetPassword(token, passw) {
    return await makeRequest("/auth/reset-password", {
        method: "POST",
        body: {
            token: token,
            password: passw
        }
    });
}


export async function apiLogout() {
    return await makeRequest("/auth/logout");
}


/* Orders */
export async function apiCheckCoupon(productId, coupon) {
    if (productId == null) return new Response(false, "No product ID provided.");
    if (coupon == null) return new Response(false, "No coupon code provided.");
    return await makeRequest(`/@me/products/${productId}/coupon/${coupon}`);
}

export async function apiCreateOrder(productId, method) {
    if (productId == null) return new Response(false, "No product ID provided.");
    if (method == null) return new Response(false, "No payment method provided.");
    return await makeRequest(`/@me/products/${productId}/purchase?method=${method}`);
}

export async function apiGetPaymentUrl(productId, method, coupon) {
    if (productId == null) return new Response(false, "No product ID provided.");
    if (method == null) return new Response(false, "No payment method provided.");
    return await makeRequest(`/@me/products/${productId}/purchase?method=${method}&coupon=${coupon}`);
}

export async function apiDownloadProduct(productId, version) {
    if (productId == null) return new Response(false, "No product ID provided.");
    window.open(getUrl(`/v2/dl/${productId}`), "_blank")
}

export async function apiRedeemKey(productId, key) {
    if (key == null) return new Response(false, "No key provided.");
    return await makeRequest(`/@me/products/${productId}/redeem`, {
        method: "POST",
        body: { key: key }
    });
}

export async function apiGetDevData(productId) {
    return await makeRequest(`/@me/products/${productId}/dev`);
}

export async function apiDevRegenKey(productId) {
    return await makeRequest(`/@me/products/${productId}/regen-key`);
}

export async function apiDevUpdateInfo(productId, data) {
    return await makeRequest(`/@me/products/${productId}/info`, {
        method: "POST",
        body: data
    });
}

export async function apiCheckDiscord() {
    return await makeRequest(`/@me/connections/discord`);
}

export async function apiJoinDiscord(productId) {
    const check = await apiCheckDiscord();
    if (!check.ok) return window.open(check.data.url, "_blank");
    return await makeRequest(`/@me/products/${productId}/join-discord`, {
        method: "POST"
    });
}
export async function apiDiscordCallback(code) {
    return await makeRequest(`/@me/connections/discord/callback`, {
        method: "PUT",
        body: { code: code }
    });
}

export async function apiCapturePaypalOrder(orderId, payerId) {
    return await makeRequest("/v2/paypal/capture", {
        method: "POST",
        body: {
            token: orderId,
            payer: payerId
        }
    });
}


// if (!IS_DEV) {
//     for (let i = 0; i < 10; i++) {
//         setInterval(() => {debugger;}, i * 10)
//     }
// }
