import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getParam, goHome } from "../../utils/General";
import { apiDiscordCallback } from "../../utils/Vantage";

/* eslint-disable */
//text-green-600
export default function DiscordCallbackPage() {
  const [resp, setResp] = useState(null);
  const code = getParam("code");
  if (!code) return goHome();
  useEffect(() => {
    apiDiscordCallback(code).then(r => {
      setResp(r);
    })
  }, [code])

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 dark:bg-gray-900">
      <div className="max-w-xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800 dark:text-zinc-200">
          {resp ? (
            <span>
              {resp.ok ? (
                <span className="text-green-600">Linked</span>
              ): (
                <span className="text-red-600">Failed Linking</span>
              )}
            </span>
          ) : (
            <span className="text-gray-500">Linking</span>
          )}
          <span> your account</span>
        </h2>
        <p className="mb-2 text-lg text-zinc-500">If you are having any issues, please contact us at <a href="mailto:support@vantage.rip" className="font-medium text-indigo-500">support@vantage.rip</a>.</p>
        <Link to="/" className="mt-3 inline-block w-96 rounded bg-indigo-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700">Go back home →</Link>
      </div>
    </div>
  )
}