import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { apiSendResetPassword } from '../../../utils/Vantage';
import { showError, showSuccess } from '../../../utils/Toast';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ResetPasswordModal({ btn }) {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const captcha = useRef(null);


  return (
    <>
      {btn && React.cloneElement(btn, { onClick: () => setIsOpen(true) })}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-700">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900 dark:text-white"
                  >
                    Reset Password
                  </Dialog.Title>

                  <div className="mt-2">
                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" autoComplete="off" onChange={e => setEmail(e.target.value)} />
                    <div className="text-center flex align-middle justify-center"><div className="captcha" data-protectedby="captcha"></div></div>
                  </div>
                  <ReCAPTCHA
                    ref={captcha}
                    theme="dark"
                    sitekey="6LebnT0mAAAAAGtF8aAa4A9HsGk1A8q8q4AuDhK7"
                    size="invisible" />
                  <div className="mt-4">
                    <button
                      type="button" onClick={async (e) => {
                        e.preventDefault();
                        const { ok, msg } = await apiSendResetPassword(email, await captcha.current.executeAsync());
                        if (!ok) return showError(msg);
                        showSuccess(msg);
                        setIsOpen(false);

                      }}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Send E-Mail
                    </button>

                    <button
                      type="button"
                      class="ml-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      onClick={() => setIsOpen(false)}>
                      Cancel
                    </button>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
