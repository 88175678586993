import { useEffect, useState } from "react"
import { apiDeleteConnection, apiGetConnections, showAndNotify } from "../utils/Vantage"

export default function ConnectionsPage() {
  const [connections, setConnections] = useState(null);
  useEffect(() => {
    apiGetConnections().then(({ok, data}) => {
      if (ok) setConnections(data);
    })
  }, [])
  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white dark:bg-gray-900">
      <div className="max-w-2xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800 text-left dark:text-zinc-200">Connections</h2>
        {connections ? (
          connections.length > 0 ? (
            <div className="mt-3">
              {connections.map((c, i) => (
                <div key={i} className="flex items-center justify-between bg-gray-100 rounded-md px-4 py-3 dark:bg-gray-800">
                  <div className="flex items-center">
                    <img src={`/img/icon/connections/${c.name}.svg`} alt={c.name} className="w-8 h-8 rounded-full" />
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-200">{c.name}</p>
                    </div>
                  </div>
                  <button className="text-sm font-medium text-red-500 hover:text-red-600" onClick={async() => {
                    await showAndNotify(apiDeleteConnection, c.name);
                    setConnections(connections.filter(c2 => c2.name !== c.name));
                  }}>Remove</button>
                </div>
              ))}
            </div>
          ) : (
            <p className="mb-2 text-lg text-zinc-500">You have no connections.</p>
          )
        ) : (
          <p className="mb-2 text-lg text-zinc-500">Loading...</p>
        )}
      </div>
    </div>
  )
}