import { useState } from "react"
import Skeleton from "../components/Loading/Skeleton";
import { rn } from "../utils/General";

export default function PrivacyPage() {
  const [policy, setPolicy] = useState(null);
  setTimeout(() => {
    setPolicy([
      {
        title: 'Information we collect',
        content: "We collect information from you when you register on our site, place an order, fill out a form or enter information on our site. We may collect: names, emails, payment information, and other information you provide us. For security purposes, we may log IP addresses according to the circumstances, however, using VPNs or Proxies of any sort are not forbidden, but rather, encouraged."
      },
      {
        title: "Security of your information",
        content: "We hash all important information that you don't want to be leaked. Although hashing may prevent from many attacks, it is not foolproof. We recommend using a unique password for our website and not sharing any information with anyone."
      },
      {
        title: 'How we use your information',
        content: "We may use your information for anything related to our services. This includes, but is not limited to: processing orders, sending emails, and improving our services. We may also use your information to contact you. If consented, for renewing products, we may automatically charge your payment method on file via our third parties."
      },
      {
        title: 'How we protect your information',
        content: "We implement a variety of security measures when a user places an order, enters, submits, or accesses their information to maintain the safety of your personal information. All transactions are processed through a gateway provider and are not stored or processed on our servers. We do not have access to any personal information rather than your email."
      },
      {
        title: "Data retention",
        content: "We will indefinitely retain your information until you request for it to be deleted. You may request for your information to be deleted by contacting us. We will delete your information within 30 business days of your request. To contact, please, email us at support@vantage.rip"
      },
      {
        title: 'Cookies',
        content: "We require the usage of cookies to save your sessions on browsers. Each request requires a cookie, as without cookies, the site would not be functional."
      },
      {
        title: "Third-party links",
        content: "We may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites."
      },
      {
        title: "Third-party disclosure",
        content: "We use third parties to process certain parts of the website. We may share: Your IP, Email, and any other data shared, other than your hashed password. We may disclose your information to these third parties, however, we will never sell your information to third parties."
      },
      {
        title: "Security breaches",
        content: "In the event of a security breach, we will notify you within 72 business hours of the breach. We will also notify the proper authorities. To be safe, please, use a unique password for our website and do not share anything with anyone."
      },
    ])
  }, 2_000);
  return (
    <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
      <img src="/img/beams.jpg" alt="" className="fixed top-48 left-1/2 -translate-x-2/3 -translate-y-1/2 max-w-none" width="1308" />
      <div className="absolute inset-0 bg-[url(/img/grid.svg)] bg-top [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      <div className="relative w-full px-6 py-12 bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28 rounded-lg">
        <div className="max-w-prose mx-auto lg:text-lg">
          <img src="/img/privacyPolicy.svg" alt="privacy policy text" />
        </div>
        <div className="mt-4 prose prose-slate mx-auto lg:prose-lg">
          {policy ? policy.map((policy, index) => (
            <div key={index} className="mt-8">
              <h2 >{policy.title}</h2>
              <p className="text-md">{policy.content}</p>
            </div>
          )) : (
            /* eslint-disable */
            <Skeleton size={4} content={(
              <>
                <h2 className="h-8 bg-gray-200 rounded animate-pulse" style={{
                  maxWidth: `${rn(40, 80)}%`
                }}></h2>
                <p className="text-md w-full h-4 bg-gray-200 rounded animate-pulse"></p>
                <p className="text-md w-full h-4 bg-gray-200 rounded animate-pulse"></p>
                <p className="text-md w-full h-4 bg-gray-200 rounded animate-pulse" style={{
                  maxWidth: `${rn(20, 40)}%`
                }}></p>
              </>
            )} classes="mt-8" />
            /* eslint-enable */
          )}
        </div>
      </div>
    </div>
  )
}