import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { showError } from '../../utils/Toast';
import { apiCheckCoupon, apiGetPaymentUrl, apiGetPurchaseData, apiGetUser } from '../../utils/Vantage';
import ProductKeyModal from './Modal/ProductKeyModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function skeleton(classes, conditional) {
  return conditional ? classes : 'animate-pulse ' + classes;
}

export default function ProductPaymentPopup({ button, productId }) {
  const [product, setProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const customPaymentMethods = [
      {
        id: 'custom-other',
        name: 'Other',
        type: 'custom',
        enabled: true,
        link: 'https://spezz.exchange/store/product/61-rise-6123/'
      },
      {
        id: 'custom-paypal',
        name: 'Paypal',
        type: 'custom',
        enabled: true,
        link: 'https://risealts.bgng.io/product/rise-client'
      }
    ];

    if (!open) return;
    apiGetUser().then(({ ok }) => {
      setIsLoggedIn(ok);
    });
    apiGetPurchaseData(productId).then(({ ok, msg, data }) => {
      if (!ok) {
        showError(msg);
        return setOpen(false);
      }

      let paymentMethods = [...data.payment_methods];
      if (data.title && data.title.includes("Rise")) {
        customPaymentMethods.forEach(customMethod => {
          if (!paymentMethods.some(method => method.id === customMethod.id)) {
            paymentMethods.push(customMethod);
          }
        });
      }

      setProduct({ ...data, payment_methods: paymentMethods });
      setPaymentMethod(paymentMethods[0]);
    });
  }, [open, productId]);

  return (
    <>
      {React.cloneElement(button, { onClick: () => setOpen(true) })}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-xl dark:bg-gray-900">
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                      <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5 min-h-full dark:bg-gray-800">
                        {product &&
                          <img src={product.image} alt={"Product img"} className="object-cover object-center" />
                        }
                      </div>
                      <div className="sm:col-span-8 lg:col-span-7">
                        {/* Product Title */}
                        <h2 className={skeleton("text-2xl font-bold text-gray-900 sm:pr-12 dark:text-gray-100", product)}>
                          {product ? product.title : "Loading"}
                        </h2>
                        {/* Product Information */}
                        <section aria-labelledby="information-heading" className="mt-2">
                          <h3 id="information-heading" className="sr-only">
                            Product information
                          </h3>
                          {/* Product Price */}
                          <p className={skeleton("text-2xl text-gray-900 dark:text-gray-200", product)}>
                            {product ? (
                              coupon ? (
                                <>
                                  <span className="text-green-800 dark:text-green-500">${coupon.price_new}</span>
                                  <span className="text-black line-through ml-2 dark:text-gray-200">${coupon.price_before}</span>
                                </>
                              ) : `$${product.price}`
                            ) : "$29.99"}
                          </p>

                          {/* Product Tags */}
                          {product ? (product.tags.length > 0 ? (
                            <div className="mt-6">
                              <h4 className="sr-only">Tags</h4>
                              <div className="flex items-center align-middle">
                                <div className="flex items-center align-middle">
                                  {product.tags.map((tag) => (
                                    <button key={tag} className="rounded-full bg-indigo-600 text-white text-sm pb-0.5 px-2 mr-0.5">{tag}</button>
                                  ))}
                                </div>
                                <p className="sr-only">{product.tags.length} tags</p>
                                <p className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-200">
                                  {product.tags.length} tags
                                </p>
                              </div>
                            </div>
                          ) : "") : "..."}
                        </section>

                        <section aria-labelledby="options-heading" className="mt-5">
                          <h3 id="options-heading" className="sr-only">
                            Product Coupon
                          </h3>

                          <div>
                            {/* Description */}
                            <div className="mb-5">
                              <h4 className="text-sm font-bold text-gray-900 dark:text-gray-100">Description</h4>
                              <p className="mt-1 space-y-6 text-sm text-gray-300">
                                {product ? product.description : "Loading"}
                              </p>
                            </div>

                            {/* Coupon Codes */}
                            <form className="mb-5" onSubmit={async (e) => {
                              e.preventDefault();
                              const coupon = document.getElementById("coupon").value;
                              if (!coupon) return showError("Please enter a coupon code");
                              const { ok, msg, data } = await apiCheckCoupon(productId, coupon);
                              if (!ok) return showError(msg);
                              setCoupon(data);
                            }}>
                              <h4 className="text-sm font-bold text-gray-900 dark:text-gray-100">Coupon Code</h4>
                              <div className="mt-1 flex space-x-3">
                                <div className="flex-auto">
                                  <label htmlFor="coupon" className="sr-only">Coupon Code</label>
                                  <input type="text" name="coupon" id="coupon" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter coupon code" required="" autoComplete="off" />
                                </div>
                                <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="coupon-btn">
                                  Apply
                                </button>
                              </div>
                            </form>

                            {/* Payment Methods */}
                            <div className="mb-5">
                              <div className="flex items-center justify-between">
                                <h4 className="text-sm font-medium text-gray-900">Payment Method</h4>
                              </div>

                              {product ? (
                                <RadioGroup value={paymentMethod} onChange={setPaymentMethod}>
                                  <RadioGroup.Label className="sr-only">Choose a payment method</RadioGroup.Label>
                                  <div className="mt-1 grid grid-cols-4 gap-2">
                                    {product.payment_methods.map(({ id, name, type, enabled }) => (
                                      <RadioGroup.Option
                                        key={id}
                                        value={name}
                                        disabled={!enabled}
                                        className={({ active }) =>
                                          classNames(
                                            enabled
                                              ? 'cursor-pointer bg-white text-gray-900 shadow-sm dark:text-gray-300 dark:bg-gray-700 dark:border-0 dark:hover:bg-gray-600'
                                              : 'cursor-not-allowed bg-gray-50 text-gray-200',
                                            active ? 'ring-2 ring-indigo-500' : '',
                                            'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                          )
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <RadioGroup.Label as="span">{name}</RadioGroup.Label>
                                            {enabled ? (
                                              <span
                                                className={classNames(
                                                  active ? 'border' : 'border-2',
                                                  checked ? 'border-indigo-500' : 'border-transparent',
                                                  'pointer-events-none absolute -inset-px rounded-md'
                                                )}
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                              >
                                                <svg
                                                  className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                  viewBox="0 0 100 100"
                                                  preserveAspectRatio="none"
                                                  stroke="currentColor"
                                                >
                                                  <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                </svg>
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    ))}

                                    {/* Key Method */}
                                    <ProductKeyModal productId={productId} btn={(
                                      <RadioGroup.Option
                                        key={"key"}
                                        value={"key"}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'ring-2 ring-indigo-500' : '',
                                            'cursor-pointer bg-white text-gray-900 shadow-sm group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300 dark:border-0'
                                          )
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <RadioGroup.Label as="span">Key</RadioGroup.Label>
                                            <span
                                              className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-md'
                                              )}
                                              aria-hidden="true"
                                            />
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    )} />

                                    {/* Custom "Other" and "Paypal" Payment Methods */}
                                    {product.title.includes("Rise") && (
                                      <>
                                        <a href="https://spezz.exchange/store/product/61-rise-6123/" target="_blank" rel="noopener noreferrer" className="block">
                                          <RadioGroup.Option
                                            key="custom-other"
                                            value="Other"
                                            className={({ active }) =>
                                              classNames(
                                                'cursor-pointer bg-white text-gray-900 shadow-sm dark:text-gray-300 dark:bg-gray-700 dark:border-0 dark:hover:bg-gray-600',
                                                active ? 'ring-2 ring-indigo-500' : '',
                                                'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                              )
                                            }
                                          >
                                            {({ active, checked }) => (
                                              <>
                                                <RadioGroup.Label as="span">Other</RadioGroup.Label>
                                                <span
                                                  className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-md'
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              </>
                                            )}
                                          </RadioGroup.Option>
                                        </a>
                                        <a href="https://risealts.bgng.io/product/rise-client" target="_blank" rel="noopener noreferrer" className="block">
                                          <RadioGroup.Option
                                            key="custom-paypal"
                                            value="Paypal"
                                            className={({ active }) =>
                                              classNames(
                                                'cursor-pointer bg-white text-gray-900 shadow-sm dark:text-gray-300 dark:bg-gray-700 dark:border-0 dark:hover:bg-gray-600',
                                                active ? 'ring-2 ring-indigo-500' : '',
                                                'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                              )
                                            }
                                          >
                                            {({ active, checked }) => (
                                              <>
                                                <RadioGroup.Label as="span">Paypal</RadioGroup.Label>
                                                <span
                                                  className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-md'
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              </>
                                            )}
                                          </RadioGroup.Option>
                                        </a>
                                      </>
                                    )}

                                  </div>
                                </RadioGroup>
                              ) : "..."}

                            </div>

                            <button
                              type="button"
                              className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={async (e) => {
                                if (!isLoggedIn) return showError("You must be logged in to purchase this product");
                                const cpn = coupon ? coupon.coupon : null;
                                const method = product.payment_methods.find(({ name }) => name === paymentMethod);

                                const { ok, msg, data } = await apiGetPaymentUrl(productId, method.id, cpn);
                                if (!ok) return showError(msg);
                                window.open(data.link, "_blank");
                              }}
                              {...(!isLoggedIn ? { style: { cursor: "not-allowed", } } : {})}
                            >
                              Purchase
                            </button>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
