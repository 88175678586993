import { useRef, useState, useEffect } from "react";
import { goHome } from "../../utils/General";
import { showError, showSuccess } from "../../utils/Toast";
import { apiLogin, apiGetUser } from "../../utils/Vantage";
import ResetPasswordModal from "../../components/Modal/Auth/ResetPassword";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);
  const captcha = useRef(null);
  useEffect(() => {
    apiGetUser().then(({ ok }) => {
      ok && goHome();
    })
  }, [])

  return (
    <div className="flex flex-col justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <section className="bg-gray-50 dark:bg-gray-900 min-h-full">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-8 h-8 mr-2" src="/logo512.png" alt="logo" />
            Vantage Marketplace
          </p>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={async (e) => {
                e.preventDefault();
                const { ok, msg } = await apiLogin(login, password, await captcha.current.executeAsync());
                if (!ok) return showError(msg);
                showSuccess(msg);
                goHome(1000);
              }}>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email / Username</label>
                  <input type="text" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" autoComplete="off" onChange={e => setLogin(e.target.value)} />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                  <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="m-auto text-center">
                  <ReCAPTCHA
                    ref={captcha}
                    theme="dark"
                    sitekey="6LebnT0mAAAAAGtF8aAa4A9HsGk1A8q8q4AuDhK7"
                    size="invisible" />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required="" />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                    </div>
                  </div>
                  <ResetPasswordModal btn={(
                    <p className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer">Forgot password?</p>
                  )} />
                </div>
                <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign in</button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don't have an account yet? <Link to="/auth/register" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Sign up</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}