/* eslint-disable */
import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { apiDevUpdateInfo, apiDevRegenKey, apiGetDevData, showAndNotify } from '../../utils/Vantage';

export default function ProductDevModal({ productId, btn }) {
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState(null);

  function fetchProductData() {
    apiGetDevData(productId).then(({ ok, data }) => {
      if (ok) setProductData(data);
    });
  }

  useEffect(() => {
    if (!isOpen) return;
    fetchProductData();
  }, [isOpen]);

  return (
    <>
      {btn && React.cloneElement(btn, { onClick: () => setIsOpen(true) })}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <form className="fixed inset-0 overflow-y-auto" onSubmit={async(e) => {
            e.preventDefault();
            const data = Object.fromEntries(new FormData(e.target));
            await showAndNotify(apiDevUpdateInfo, productId, data);
          }}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900"
                  >
                    Developer - Manage Product
                  </Dialog.Title>
                  <section>
                    {/* Main Content */}
                    <div>
                      <div className="px-4 sm:px-0">
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Product: {productId}</p>
                      </div>
                      <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Title / Version / Price</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div className="flex flex-row gap-2">
                                <input type="text" name="title" placeholder="Title" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? productData.title : ""} />
                                <input type="text" name="version" placeholder="Version" className="w-1/4 border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? productData.version : ""} />
                                <input type="text" name="price" placeholder="Price" className="w-1/5 border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? `$${productData.meta.price}` : ""} />
                              </div>
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <input type="text" name="description" placeholder="Description" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? productData.description : ""}/>
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Image</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <input type="text" name="image" placeholder="Image URL" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? productData.meta.image : ""}/>
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Tags (Split by comma)</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <input type="text" name="tags" placeholder="Tags" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData ? productData.meta.tags.join(", ") : ""}/>
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Payment Methods</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              Contact Gowixx for payment method changes because he is too lazy to do this
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Payment Webhook</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <input type="text" name="webhook" placeholder="Webhook" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 blur-sm hover:blur-0 transition-all focus:outline-none focus:ring-2 focus:ring-blue-300" defaultValue={productData && productData.webhook}/>
                            </dd>
                          </div>
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">API Key</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="flex flex-row align-middle gap-2">
                                <input type="text" placeholder="API Key" className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 blur-sm hover:blur-0 transition-all focus:outline-none focus:ring-2 focus:ring-blue-300" disabled value={productData && productData.api}/>
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={async() => {
                                    await showAndNotify(apiDevRegenKey, productId)
                                    fetchProductData();
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                          </dd>
                          </div>
                        </dl>
                      </div>
                    </div>

                  </section>


                  <div className="mt-4">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-gray hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      Update Data
                    </button>

                    <button
                      type="button"
                      className="ms-2 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </form>
        </Dialog>
      </Transition>
    </>
  )
}
