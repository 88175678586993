// import { toast } from 'https://cdn.skypack.dev/wc-toast';
import { toast } from 'wc-toast';

export function showError(msg) {
    toast.error(msg);
}

export function showSuccess(msg) {
    toast.success(msg);
}
