import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getParam } from "../../utils/General";

export default function PaymentCancelPage() {
  const navigate = useNavigate();
  const token = getParam("token");
  useEffect(() => {
    if (!token) navigate("/");
  }, [token, navigate])

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12">
      <div className="max-w-xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800">
          Payment{" "}
          <span className="text-red-500">Cancelled</span>
        </h2>
        <p className="mb-2 text-lg text-zinc-500">If you are having any issues, please contact us at <a href="mailto:support@vantage.rip" className="font-medium text-indigo-500">support@vantage.rip</a>. <br />Reference #: {token}</p>
        <Link to="/" className="mt-3 inline-block w-96 rounded bg-indigo-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700">Go back home →</Link>
      </div>
    </div>
  )
}