import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getManyParams } from "../../utils/General";
import { apiCapturePaypalOrder, Response } from "../../utils/Vantage";

export default function PaymentCancelPage() {
  const { type, token, PayerID } = getManyParams("type", "token", "PayerID");
  const payer = PayerID;
  const [resp, setResp] = useState(null);

  useEffect(() => {
    switch (type) {
      case "paypal":
        if (!token || !payer) return setResp("No token or payer ID provided.");
        apiCapturePaypalOrder(token, payer).then(r => {
          setResp(r);
        })
        break;
      default:
        setResp(new Response(false, "Invalid payment type."));
        break;
    }
  }, [type, token, payer]);

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 dark:bg-gray-900">
      <div className="max-w-xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800 dark:text-zinc-200">
          Payment{" "}
          {resp ? (
            <>
              {resp.ok ? (
                <span className="text-green-500">Succeeded</span>
              ) : (
                <span className="text-red-500">Failed</span>
              )}
            </>
          ) : (
            <span className="text-gray-500">Loading</span>
          )}
        </h2>
        <p className="mb-2 text-lg text-zinc-500 dark:text-zinc-400">If you are having any issues, please contact us at <a href="mailto:support@vantage.rip" className="font-medium text-indigo-500">support@vantage.rip</a>. <br />Reference #: {token}</p>
        <Link to="/" className="mt-3 inline-block w-96 rounded bg-indigo-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700">Go back home →</Link>
      </div>
    </div>
  )
}